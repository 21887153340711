import { deferOnload, importOnElement } from './utils';
import { onReady } from './utils/on-ready';
import initLazyload from './front-lazy-load';
import {
    isMobile,
    isVGApp,
    isDesktop,
    supportCssFeature,
    isFirefox,
} from './utils/device';
import { executeModule, loadModules, importIf } from './utils/import-helpers';
import { isFront, isPreview } from './utils/routes';
import dynamicWidgets from './dynamic-widgets';
import { importShim } from './utils/import-shim';
import 'clsx'; // import to force webpack to include clsx in the bundle

window.$$ = (selector, parent = document) =>
    Array.from(parent.querySelectorAll(selector));

// Safari sometimes doesn't trigger on first link tap, so we need an empty event listener to make it work
// Inspired by: https://www.reddit.com/r/webdev/comments/18zjara/i_frigging_love_ios_safari/
// TODO: Might need this on buttons as well
const fixSafariDoubleTapQuirk = () => {
    if (!isMobile()) return;
    const links = document.querySelectorAll(
        '.front-container a[itemprop="url"], .front-container header a',
    );
    if (!links) return;

    /**
     * @param {PointerEvent} event - The pointerup event
     */
    links.forEach((link) => link.addEventListener('pointerup', () => null));
};

const App = async () => {
    await onReady();
    if (process.isModern) {
        importShim();
        dynamicWidgets();
    }
    initLazyload();

    fixSafariDoubleTapQuirk();

    await (async () => {
        !isPreview() &&
            executeModule(await import('./tracking/privacy-broker'));
        executeModule(
            await import('./advert/dr-edition' /* webpackMode: "eager" */),
        );

        !isPreview() &&
            executeModule(
                await import(
                    /* webpackChunkName: "advertory" */ './advertory/index'
                ),
            );
    })();

    loadModules([
        !isPreview() &&
            import('./auth/schibsted-account' /* webpackMode: "eager" */),
        importIf(() => import('./vg-nav'), [!isVGApp(), !isPreview()]),
        importIf(() => import('./tracking/data-controller'), !isPreview()),
        importIf(
            () => import('./tracking' /* webpackMode: "eager" */),
            !isPreview(),
        ),
        importIf(
            () => import(/* webpackMode: "eager" */ './front-tracking'),
            isFront() && !isPreview(),
        ),
        import('./advert/finn-blink-direct'),
        !isPreview() && import('./personalized-vgpluss'),
        importOnElement(
            () => import('./widgets/vglista-counter'),
            '.widget.widget-vglista-counter',
        ),
        import('./partnerstudio-on-front'),
        importIf(() => import('./experiments-loader'), process.isModern),

        importOnElement(
            () => import('./widgets/front-countdown'),
            '.countdown',
        ),
        importIf(
            () => import('./utils/load-video'),
            !!document.querySelector('.vgtv-video-container'),
        ),
        import('./vgtv-on-front'),
        import('./font-resizer'),
        importIf(
            () => import('./preview-video'),
            [
                !isDesktop(),
                !window.matchMedia('(prefers-reduced-motion)').matches,
            ],
        ),
        importOnElement(
            () => import('./widgets/Pluss-front/vgtv-premium-sport'),
            '[data-article-type="live-sport"]',
        ),
        importOnElement(
            () => import('./widgets/Pluss-front/vgtv-documentaries'),
            '[data-article-type="documentaries"]',
        ),
        importOnElement(
            () => import('./widgets/Pluss-front/vgtv-underholdning'),
            '#vgtv-underholdning-placeholder',
        ),
        importOnElement(
            () => import('./widgets/Pluss-front/pluss-resources'),
            '#pluss-resources',
        ),
        importOnElement(
            () => import('./widgets/Pluss-front/pluss-services'),
            '#vgpluss-services',
        ),
        import('./stale-reloader'),
    ]).forEach(executeModule);

    await deferOnload();

    loadModules([
        !isPreview() && import('./tracking/partnerstudio/viewability-tracking'),
        !isPreview() && import('./tracking/partnerstudio/click-tracking'),
        !isPreview() && import('./tracking/bfcache-tracking'),

        !isPreview() && import('./glimr/sdk'),

        importIf(
            () => import('./lazy-front'),
            supportCssFeature('content-visibility', 'auto'),
        ),
        importIf(
            () => import('./auth/continue-as-popup'),
            [!isVGApp(), !isPreview()],
        ),
        importIf(
            () => import('./vivi-loader'),
            [!isVGApp(), !(isFirefox() && isMobile()), !isPreview()],
        ),

        importOnElement(
            () => import('./hoyrelopet'),
            '.hoyrelopet-lazy-sentinel',
            { rootMargin: '400% 0px' },
        ),
        importOnElement(
            () => import('./widgets/mestlest'),
            '#mestlest-placeholder',
        ),

        isPreview() && import('./widgets/retargeting-widget'),

        !isPreview() &&
            importOnElement(
                () => import('./tracking/drfront-experiment-tracker'),
                '[data-experiment-id]',
            ),
        importOnElement(
            () => import('./widgets/godt-dishes-list'),
            '.godt-dishes-list',
        ),
        import('./darkmode-trigger'),

        importIf(
            () => import('./tracking/performance'),
            Math.random() >= 0.95 && !isPreview(),
        ),
        import('./access-localstorage-sync'),

        import('./bfcache-cleanup'),

        importIf(
            () => import('./utils/AutoRefresh'),
            !isVGApp() && !isPreview(),
        ),
    ]).forEach(executeModule);
};

App();
