import { hasSession, isLoggedIn } from '../auth/schibsted-account';

/*
 * This package is for storing key/value pairs to use for anything.
 * Currently it stores in Schibsted Account Dataobjects using schacc-gud-helper API.
 * It caches data in localStorage and syncs to the API.
 * It can only store objects on users connected to the VG spid merchant.
 * It can only store whitelisted keys (check out schacc-gud-helper on github for whitelisting).
 */
const apiPath = 'https://schacc-api.vg.no';
const GET = Symbol('GET');
const SET = Symbol('SET');

const api = async (getOrSet, key, value) => {
    const loggedIn = await isLoggedIn();
    if (!loggedIn) {
        return false;
    }

    const { uuid, sig } = await hasSession();

    let body,
        method = 'get',
        headers = {
            authorization: sig,
        };

    if (getOrSet == SET) {
        method = 'post';
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify({ value });
    }

    return fetch(`${apiPath}/api/${uuid}/${key}`, {
        method,
        headers,
        body,
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(`Api error: ${json.error}`);
            }
            return json;
        })
        .catch((err) => {
            console.warn('[UserSettings]', err);
            return false;
        });
};

/* Stores/syncs key-val in schacc-api and caches in localStorage
 */
export default class UserSettings {
    constructor() {
        this.cacheKey = 'VG_USER_SETTINGS';
        this.cacheUpdate = 3600; // 1 hour
        this.cacheStorage = window.localStorage;

        // Init cache
        if (!this.cacheStorage.getItem(this.cacheKey)) {
            this.cacheStorage.setItem(this.cacheKey, '{}');
        }

        this.provider = {
            set: api.bind(null, SET),
            get: api.bind(null, GET),
        };
    }

    triggerEvent({ type, data }) {
        const eventName = `vg:user-settings:${type}`;
        const event = new CustomEvent(eventName, {
            detail: {
                data,
                type,
            },
        });

        window.dispatchEvent(event);
    }

    has(key) {
        return this.get(key).then((val) => val !== undefined);
    }

    /* Get settings data
     */
    async getSettingsData() {
        if (!this.settingsData) {
            const data = await fetch(`${apiPath}/api/settings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(res.statusText);
                    }
                    return res.json();
                })
                .then((json) => {
                    if (json.error) {
                        throw new Error(`Api error: ${json.error}`);
                    }
                    return json;
                })
                .catch((err) => {
                    console.warn('[UserSettings/settings]', err.message);
                    return false;
                });
            this.settingsData = data;
        }

        return this.settingsData;
    }

    async getInitialValue(key) {
        const settingsData = await this.getSettingsData();

        if (settingsData && settingsData[key] !== null) {
            return this.settingsData[key].initialValue;
        }

        return undefined;
    }

    /* Sets data in storage and cache it.
     */
    async set(key, val) {
        const shouldSync = await this.provider.set(key, val);
        // Set synctime if success from provider
        const syncTime = shouldSync ? this.timeNow() : 0;

        return this.cacheSet(key, val, syncTime);
    }

    /* Try to get cached data.
     * If expired or not pulled, get from server and add to cache.
     * Checks to see if sync is needed.
     */
    async get(key) {
        let cachedData = this.cacheGet(key);

        if (cachedData && !this.cacheIsExpired(cachedData.updated)) {
            return cachedData.value;
        }

        // Expired or no data in cache, pull from provider
        let freshData = await this.provider.get(key);

        let syncTime = this.timeNow();

        if (freshData && freshData.value != null) {
            this.cacheSet(key, freshData.value, syncTime);
            return freshData.value;
        } else {
            // Empty data from api.

            // Check for initial value
            const initialValue = await this.getInitialValue(key);
            if (initialValue != undefined) {
                this.cacheSet(key, initialValue, syncTime);
                return initialValue;
            }

            // Cache empty value
            const value = null;
            this.cacheSet(key, value, syncTime);
            return value;
        }
    }

    async getKeys(keys) {
        let data = {};

        await Promise.all(
            keys.map((key) =>
                this.get(key).then((value) => {
                    data[key] = value;
                }),
            ),
        );

        return data;
    }

    delete(key) {
        return this.set(key, null);
    }

    timeNow() {
        return Math.floor(Date.now() / 1000);
    }

    clearCache() {
        return this.cacheStorage.setItem(this.cacheKey, '{}');
    }

    cacheIsExpired(updated) {
        return updated + this.cacheUpdate < this.timeNow();
    }

    cacheGet(key) {
        try {
            let data = JSON.parse(this.cacheStorage.getItem(this.cacheKey));

            if (data[key]) {
                return data[key];
            }
        } catch (e) {
            // empty catch
        }
        return false;
    }

    cacheSet(key, val, syncTime) {
        try {
            let data = JSON.parse(this.cacheStorage.getItem(this.cacheKey));

            data[key] = {
                value: val,
                updated: this.timeNow(),
                syncTime,
            };

            this.cacheStorage.setItem(this.cacheKey, JSON.stringify(data));

            this.triggerEvent({
                data: {
                    [key]: data[key],
                },
                type: 'set',
            });

            return true;
        } catch (e) {
            console.warn('[UserSettings] cache error');
            return false;
        }
    }
}
